import React, { ReactNode } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import reportWebVitals from './reportWebVitals';
import Web3Context from './context/Web3Context';
import useWeb3State from './hooks/useWeb3State';

const Provider = ({ children }: { children: ReactNode }) => {
  const [web3State, initConnect, resetApp] = useWeb3State();

  return <Web3Context.Provider value={{ ...web3State, initConnect, resetApp }}>{children}</Web3Context.Provider>;
};

ReactDOM.render(
  <React.StrictMode>
    <Provider>
      <ToastContainer />
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

reportWebVitals();
