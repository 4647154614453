import React from 'react';

const Founder = () => (
  <section className='container mx-auto py-10 sm:py-16 my-2 tracking-wider text-center'>
    <h2 className='text-white text-4.5xl font-extrabold mb-12 font-Montserrat'>Founders</h2>
    <div className='flex justify-center flex-col space-y-12 lg:space-y-0 lg:flex-row lg:space-x-16 xl:space-x-20'>
      <div className='flex flex-col items-center'>
        <img className='rounded-full mb-6' src='images/founder_jj.png' alt='founder_jj' />
        <h3 className='font-extrabold text-2xl text-founderGray font-Montserrat'>JJ</h3>
        <h4 className='text-gray font-black my-2'>Developer</h4>
        <p className='text-gray'>@JJ_AGF</p>
      </div>
      <div className='flex flex-col items-center'>
        <img className='rounded-full mb-6' src='images/founder_lote.png' alt='founder_lote' />
        <h3 className='font-extrabold text-2xl text-founderGray font-Montserrat'>Lote</h3>
        <h4 className='text-gray font-black my-2'>Marketing</h4>
        <p className='text-gray'>@LoteAGF</p>
      </div>
      <div className='flex flex-col items-center'>
        <img className='rounded-full mb-6' src='images/founder_daniel.png' alt='founder_daniel' />
        <h3 className='font-extrabold text-2xl text-founderGray font-Montserrat'>Daniel</h3>
        <h4 className='text-gray font-black my-2'>Artist</h4>
        <p className='text-gray'>@DanielAGsartist</p>
      </div>
    </div>
  </section>
);

export default Founder;
