import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { Header, Footer } from './components';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Collection from './views/Collection';
import Home from './views/Home';

const App = () => (
  <div className='bg-black font-sans' style={{ minWidth: 320 }}>
    <Router>
      <Header />
      <Switch>
        <Route path='/' exact>
          <Home />
        </Route>
        <Route path='/collection'>
          <Collection />
        </Route>
      </Switch>
      <Footer />
    </Router>
  </div>
);

export default App;
