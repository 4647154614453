import styles from './Hero.module.scss';
import React, { useState, useEffect, useContext } from 'react';
import useAstroGators from '../../../../hooks/useAstroGators';
import Web3Context from '../../../../context/Web3Context';
import SVG from 'react-inlinesvg';

interface AstroGatorsPanelProps {}

const Hero: React.FC<AstroGatorsPanelProps> = () => {
  const web3Controller = useContext(Web3Context);
  const { connected, address, chainId } = web3Controller;

  const [astro, tokenNumber, setTokenNumber, mintAstro] = useAstroGators(web3Controller);
  // const maxPurchase = useMemo(() => {
  //   const ableToSupply = parseInt(astro.maxAstroGators) - parseInt(astro.totalSupply);
  //   return ableToSupply < 20 ? ableToSupply : 20;
  // }, [astro]);

  const [second, setSecond] = useState(0);
  const [minute, setMinute] = useState(0);
  const [hour, setHour] = useState(0);
  const [day, setDay] = useState(0);
  const [startSell, setstartSell] = useState(astro.isActive);
  const [phase, setphase] = useState(1);

  const isWave2 = () => {
    const sellTime = Date.UTC(2021, 11, 17, 1);
    const now = Date.now();

    return now - sellTime >= 0;
  };

  const getTime = () => {
    const sellTime = Date.UTC(2021, 8, 20, 1);
    const now = Date.now();
    const timeLeft = (sellTime - now) / 1000;

    if (timeLeft < 0) {
      return setstartSell(true);
    }

    const tSecond = Math.floor(timeLeft % 60);
    const tMinute = Math.floor((timeLeft / 60) % 60);
    const tHour = Math.floor((timeLeft / 3600) % 24);
    const tDay = Math.floor(timeLeft / 86400);
    setSecond(tSecond);
    setMinute(tMinute);
    setHour(tHour);
    setDay(tDay);
  };

  const whichPhase = (t: number) => {
    if (t <= 3000) {
      setphase(1);
    } else if (t <= 5000) {
      setphase(2);
    } else if (t <= 7000) {
      setphase(3);
    } else if (t <= 8500) {
      setphase(4);
    } else {
      setphase(5);
    }
  };

  useEffect(() => {
    getTime();
    const interval = setInterval(() => {
      getTime();
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    whichPhase(parseInt(astro.totalSupply));
  }, [astro.totalSupply]);

  const inputClassNames = [
    'appearance-none',
    'bg-dark',
    'h-16',
    'rounded-lg',
    'text-center',
    'flex-grow',
    'md:w-24',
    'outline-none',
    'font-black',
    styles.input,
  ];

  // const isSoldOut = parseInt(astro.totalSupply) >= 10000;
  const isSoldOut = true;

  // const isToMint = startSell && connected && chainId === 1;
  const isToMint = false;

  const numberWithCommas = (num: any) => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  const eachPhaseTotalSupply = () => {
    let s;
    if (phase == 1) {
      s = 3000;
    } else if (phase == 2 || phase == 3) {
      s = 2000;
    } else if (phase == 4) {
      s = 1500;
    } else {
      s = 1400;
    }
    return s;
  };

  const phaseText = (t: number) => {
    if (connected) {
      return `${phase + 1}nd Wave - ${numberWithCommas(t)} / ${eachPhaseTotalSupply()}`;
    } else {
      return 'Please connect to your wallet';
    }
  };

  const eachPhaseSold = (t: number) => {
    let num;
    if (phase == 1) {
      num = t;
    } else if (phase == 2) {
      num = t - 3000;
    } else if (phase == 3) {
      num = t - 5000;
    } else if (phase == 4) {
      num = t - 7000;
    } else {
      num = t - 8500;
    }
    return num;
  };

  const startSellPanel = () => (
    <div
      className='bg-soonDark mx-5 py-6 md:py-10 px-8 md:px-14 rounded-2xl lg:mx-auto mt-12'
      style={{ maxWidth: 984 }}
    >
      <div
        className={`bg-gradient text-white font-black text-center rounded-lg h-10 leading-10 overflow-hidden ${styles.gradient}`}
      >
        {chainId !== 1 ? (
          <div className='z-20 relative text-black font-normal text-tiny' style={{ background: '#fccd49' }}>
            Please switch to Ethereum network.
          </div>
        ) : (
          // <div className='z-20 relative'>{isSoldOut ? 'SOLD OUT' : phaseText(parseInt(astro.totalSupply))}</div>
          <div className='z-20 relative'>{isSoldOut ? '???' : phaseText(parseInt(astro.totalSupply))}</div>
        )}
        {/* <div
          className={`${styles.antiGradient}`}
          style={{
            width: `calc(100% - ${eachPhaseSold(parseInt(astro.totalSupply))} / ${eachPhaseTotalSupply()} * 100%)`,
          }}
        ></div> */}
      </div>
      {!isSoldOut && (
        <div className='mt-6 md:mt-8 flex flex-col md:flex-row justify-center items-center space-y-4 md:space-y-0 md:space-x-4'>
          <a href='https://lootex.io/stores/astrogator' target='_blank' rel='noreferrer' className={`${styles.link}`}>
            View on Lootex
          </a>
          <a
            href='https://opensea.io/collection/astrogator-fam'
            // href={
            //   connected
            //     ? `https://opensea.io/${address}?identifier=${address}
            //     &search[sortBy]=LAST_TRANSFER_DATE&search[sortAscending]=false`
            //     : 'https://opensea.io'
            // }
            target='_blank'
            rel='noreferrer'
            className={`${styles.link}`}
          >
            View on OpenSea
          </a>
        </div>
      )}
      <div className='flex items-center justify-center text-followGray mt-6 md:mt-8'>
        <div className='font-black pr-3'>Follow us on</div>
        <div className='space-x-3 flex justify-center'>
          <a className='inline-block' href='https://twitter.com/astrogator_fam' target='_blank' rel='noreferrer'>
            <SVG src={'images/icons/twitter.svg'} width={28} height={28} />
          </a>
          <a className='inline-block' href='https://discord.gg/EhzTvTkXkP' target='_blank' rel='noreferrer'>
            <SVG src={'images/icons/discord.svg'} width={28} height={28} />
          </a>
        </div>
      </div>
    </div>
  );

  const unSellPanel = () => (
    <div
      className='bg-soonDark mx-5 py-6 md:py-10 px-8 md:px-14 rounded-2xl lg:mx-auto mt-12'
      style={{ maxWidth: 984 }}
    >
      <div className='bg-moreDark text-white font-black text-center rounded-lg py-2 md:py-3'>COMING SOON</div>
      <div className='flex mt-8 flex-col lg:flex-row md:h-91'>
        <div className='lg:mr-6'>
          <p className='font-black mb-1'>Available on</p>
          <div className='flex'>
            <div className='ml-2 rounded-full bg-white' style={{ width: 6, height: 6, marginTop: 10 }}></div>
            <p className='ml-2 flex-shrink'>9:00 am (Asia/Taipei) Monday, September 20th 2021</p>
          </div>
          <div className='flex'>
            <div className='ml-2 rounded-full bg-white' style={{ width: 6, height: 6, marginTop: 10 }}></div>
            <p className='ml-2 flex-shrink'>9:00 pm (USA/New York) Sunday, September 19th 2021</p>
          </div>
        </div>
        <div className='flex space-x-2 md:space-x-4 justify-center mt-6 md:mt-8 lg:mt-0'>
          <div className={`bg-dateDark rounded-lg px-6 py-3 flex flex-col items-center ${styles.dateBlock}`}>
            <span className='font-extrabold text-2xl pb-3'>{day}</span>
            <span className='text-gray font-black leading-3 text-xs'>Days</span>
          </div>
          <div className={`bg-dateDark rounded-lg px-6 py-3 flex flex-col items-center ${styles.dateBlock}`}>
            <span className='font-extrabold text-2xl pb-3'>{hour}</span>
            <span className='text-gray font-black leading-3 text-xs'>Hours</span>
          </div>
          <div className={`bg-dateDark rounded-lg px-6 py-3 flex flex-col items-center ${styles.dateBlock}`}>
            <span className='font-extrabold text-2xl pb-3'>{minute}</span>
            <span className='text-gray font-black leading-3 text-xs'>Minutes</span>
          </div>
          <div className={`bg-dateDark rounded-lg px-6 py-3 flex flex-col items-center ${styles.dateBlock}`}>
            <span className='font-extrabold text-2xl pb-3'>{second}</span>
            <span className='text-gray font-black leading-3 text-xs'>Seconds</span>
          </div>
        </div>
      </div>
      <div className='flex items-center justify-center mt-6 md:mt-8'>
        <div className='font-black text-followGray pr-3'>Follow us on</div>
        <div className='space-x-3 flex justify-center'>
          <a className='inline-block' href='https://twitter.com/astrogator_fam' target='_blank' rel='noreferrer'>
            <SVG src={'images/icons/twitter.svg'} width={28} height='auto' />
          </a>
          <a className='inline-block' href='https://discord.gg/EhzTvTkXkP' target='_blank' rel='noreferrer'>
            <SVG src={'images/icons/discord.svg'} width={28} height='auto' />
          </a>
        </div>
      </div>
    </div>
  );

  const handleAddBtn = () => {
    setTokenNumber((prevTokenNumber: any) => {
      if (prevTokenNumber >= 20) return 20;
      return prevTokenNumber + 1;
    });
  };

  const handleMinusBtn = () => {
    setTokenNumber((prevTokenNumber: any) => {
      if (prevTokenNumber <= 1) return 1;
      return prevTokenNumber - 1;
    });
  };

  const handleInput = (e: any) => {
    const { value } = e.target;
    const valueNumber = parseInt(value);
    if (valueNumber > 20) {
      setTokenNumber(20);
    } else if (valueNumber < 1) {
      setTokenNumber(1);
    } else {
      setTokenNumber(parseInt(value));
    }
  };

  const mintPanel = () => (
    <div className='md:flex md:justify-center lg:justify-start items-center'>
      <div className='md:flex'>
        {isWave2() && (
          <>
            <div className='space-x-3 flex items-center'>
              <button onClick={handleMinusBtn}>
                <img src='images/icons/decremental.png' alt='' />
              </button>
              <input
                className={inputClassNames.join(' ')}
                value={tokenNumber}
                type='number'
                min={1}
                max={20}
                onChange={handleInput}
              />
              <button onClick={handleAddBtn}>
                <img src='images/icons/incremental.png' alt='' />
              </button>
            </div>
            <button
              disabled={!isToMint}
              onClick={mintAstro}
              className={`h-16 w-full my-4 md:my-0 md:w-28 md:px-2 md:mx-4 box-content rounded-lg font-black text-dark ${
                isToMint ? styles.brandBtn : 'bg-dark cursor-not-allowed'
              }`}
            >
              Mint now
            </button>
          </>
        )}
        {!isWave2() && (
          <button
            disabled={true}
            className={`h-16 w-full my-4 md:my-0 md:px-2 md:mx-4 box-content rounded-lg font-black text-dark ${
              isToMint ? styles.brandBtn : 'bg-dark cursor-not-allowed'
            }`}
          >
            Minting will open at 1:00 am, 12/17 (UTC)
          </button>
        )}
      </div>
      <div className='flex justify-center'>
        <img src='images/icons/ethereum.png' alt='ethereum_icon' />
        <div className='font-extrabold text-2xl flex items-center'>
          0.04
          <span className='text-xs font-black text-gray pl-3'>EACH</span>
        </div>
      </div>
    </div>
  );

  const finishedMintPanel = () => (
    <div className='space-y-4 md:space-y-0 md:space-x-4 flex flex-col md:flex-row'>
      <a target='_blank' rel='noreferrer' href='https://lootex.io/stores/astrogator' className={`${styles.brandBtn} ${styles.storeBtn}`}>
        Browse on Lootex
      </a>
      <a target='_blank' rel='noreferrer' href='https://opensea.io/collection/astrogator-fam' className={`${styles.brandBtn} ${styles.storeBtn}`}>
        Browse on OpenSea
      </a>
    </div>
  );

  return (
    <section
      className='bg-black bg-no-repeat bg-cover md:bg-contain 2xl:bg-cover bg-top py-12 md:py-18 text-white tracking-wider md:h-823'
      style={{ backgroundImage: 'url("images/cover_background.png")' }}
    >
      <div className='container'>
        <div className='flex flex-col lg:flex-row'>
          <img
            src='images/astrogator_coming.png'
            className='w-full mx-auto lg:ml-0 lg:mr-8 xl:mr-16 md:w-ph mb-6 md:mb-12'
            alt='AstroGator is coming'
          />
          <div className='xl:my-auto'>
            <h2 className='font-extrabold text-5xl font-Montserrat'>AstroGator</h2>
            <h3 className='py-5'>The Remain Amount: {numberWithCommas(10000 - parseInt(astro.totalSupply))}</h3>
            <p className='pb-12 text-gray'>
              AstroGators are the unique creatures in Metaspace. They detected the special signal from the earth while
              travelled by AGF-1 spaceship. Following the radar, they are ready to come. Let&apos;s join us being the
              friendly host! All 10,000 are minted from smart contract and distributed at a fixed price of 0.04 ETH
              each. No bonding curve or price tiers.
            </p>
            {isSoldOut ? finishedMintPanel() : mintPanel()}
          </div>
        </div>
      </div>
      {startSell ? startSellPanel() : unSellPanel()}
    </section>
  );
};

export default Hero;
