import React from 'react';

const SalesPlan = () => (
  <section className='container mx-auto text-white tracking-wider py-12'>
    <h2 className='font-extrabold pb-5 text-4.5xl font-Montserrat'>Sales Plan</h2>
    <div className='space-y-6 text-gray' style={{ maxWidth: 1210 }}>
      <p>
        There will be 10,000 randomly generated ERC-721 NFTs stored on the Ethereum blockchain, including 100 giveways
        of 10,000.
      </p>
      <ul>
        <li className='flex items-center'>
          <span className='bg-white inline-block rounded-full mx-2' style={{ width: 6, height: 6 }}></span>
          Wave 1-
        </li>
        <li>　9:00 AM (Asia/ Taipei) Monday, September 20th 2021</li>
        <li>　9:00 PM (USA/ New York) Sunday, September 19th 2021</li>
        <li className='flex items-center'>
          <span className='bg-white inline-block rounded-full mx-2' style={{ width: 6, height: 6 }}></span>
          Wave 2-
        </li>
        <li>　9:00 AM (Asia/ Taipei) Friday, December 17th 2021</li>
        <li>　9:00 PM (USA/ New York) Thursday, December 16th 2021</li>
        <li className='flex items-center'>
          <span className='bg-white inline-block rounded-full mx-2' style={{ width: 6, height: 6 }}></span>
          ???
        </li>
      </ul>
      <p>Follow @astrogator_fam on Twitter to get more details!</p>
    </div>
  </section>
);

export default SalesPlan;
