import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import Web3Context from '../../../context/Web3Context';
import styles from './CollectionContent.module.scss';
import SVG from 'react-inlinesvg';

import axios from 'axios';
import ASTROGATORS_ABI from '../../../constants/abis/astrogators.json';
import { ASTRO_ADDRESSES } from '../../../constants';
import { AbiItem } from 'web3-utils';
import Web3 from 'web3';

const CollectionContent = () => {
  const web3Controller = useContext(Web3Context);
  const { connected, address, chainId, initConnect } = web3Controller;

  const [astroList, setAstroList] = useState<any>([]);

  const getNftInfo = async (owner: any) => {
    const web3 = new Web3('https://mainnet.infura.io/v3/1efc60f72ff14f738c91db5b35903c18');
    // const web3 = new Web3('https://rinkeby.infura.io/v3/1efc60f72ff14f738c91db5b35903c18'); // testnet
    const contract = new web3.eth.Contract(ASTROGATORS_ABI as AbiItem[], ASTRO_ADDRESSES[1]);
    const { balanceOf, tokenOfOwnerByIndex, tokenURI } = contract.methods;
    const balance = await balanceOf(owner).call();
    const tokenList = [];

    if (balance > 0) {
      for (let i = 0; i < balance; i++) {
        const id = await tokenOfOwnerByIndex(owner, i).call();
        const uri = await tokenURI(parseInt(id)).call();
        const { data: metaData } = await axios.get(uri);
        const tempObj = {
          id: parseInt(id),
          metaData,
        };
        tokenList.push(tempObj);
      }
    }
    setAstroList(tokenList);
  };

  const textPanel = () => {
    const h3Text = connected ? 'You don’t have AstroGators yet.' : 'Please connect to your wallet';
    const btn = connected ? (
      <Link to='/' className={`block mx-auto ${styles.link}`}>
        Get now
      </Link>
    ) : (
      <button className={`inline-block ${styles.link}`} onClick={initConnect}>
        Connect Wallet
      </button>
    );
    return (
      <div className='flex flex-col items-center mt-24'>
        <h3 className='text-2xl mb-12 text-center font-Montserrat'>{h3Text}</h3>
        {btn}
      </div>
    );
  };

  const mapAstro = () =>
    astroList.map(({ id, metaData }: { id: any; metaData: any }) => (
      <div className={`flex flex-col mx-auto ${styles.grid}`} key={id}>
        <img src={metaData.image} className='w-full' alt='astro' />
        <h4 className='text-2xl font-extrabold font-Montserrat mt-3 mb-2'>{metaData.name}</h4>
        <h5 className='text-gray mb-3'>#{id}</h5>
        <span>View on</span>
        <div className={`flex items-center mt-2 ${styles.linkGroup}`}>
          <a
            className='flex items-center justify-center w-6/12 h-full'
            href={`https://lootex.io/assets/0x2af743179e9d464c9bd687e90bc18f5ea146950f/${id}`}
            target='_blank'
            rel='noreferrer'
          >
            <SVG src={'images/logo_lootex.svg'} width={78} height={24} />
          </a>
          <a
            className={`flex items-center justify-center w-6/12 h-full text-xs lg:text-tiny ${styles.borderLeft}`}
            href={`https://opensea.io/assets/0x2af743179e9d464c9bd687e90bc18f5ea146950f/${id}`}
            target='_blank'
            rel='noreferrer'
          >
            <img className={styles.openseaIcon} src='images/icons/opensea.jpeg' alt='opensea' />
            OpenSea
          </a>
        </div>
      </div>
    ));

  const astroPanel = () => (
    <>
      <div className='grid grid-cols-1 gap-12 mt-12 md:grid-cols-2 lg:grid-cols-3'>{mapAstro()}</div>
      <Link to='/' className={`block mx-auto mt-24 mb-18 md:mb-12 ${styles.link}`}>
        Get more
      </Link>
    </>
  );

  useEffect(() => {
    if (address) {
      getNftInfo(address);
      // getNftInfo('0x113224cffb35f64b0c80ad489d068c44fc32b149'); // 1
      // getNftInfo('0xd0a64e2a812d2ecd39554afb7964aa8d6246d494'); // 10
      // getNftInfo('0xc51215c8ccb1ebb4303fc549f93b10a28d8bd7d6'); // 15
      // getNftInfo('0x1c20f92de419d054781f0d4607325af2198b9d1f'); // 23
    }
  }, [address]);

  return (
    <div
      className={`text-white tracking-wider font-extrabold py-12 md:py-18 flex flex-col
      ${styles.container}`}
      style={{ minHeight: 'calc(100vh - 263px)' }}
    >
      <h2 className='text-4.5xl mb-6 font-Montserrat'>My AstroGators</h2>
      <p className='text-gray'>
        AstroGators are 10,000 randomly generated ERC-721 NFTs stored on the Ethereum blockchain.
      </p>
      <p className='text-gray'>
        Contract Address:{' '}
        <a
          href='https://etherscan.io/address/0x2af743179e9d464c9bd687e90bc18f5ea146950f'
          target='_blank'
          rel='noreferrer'
          className='underline'
        >
          0x2af7...950f
        </a>
      </p>
      {astroList.length > 0 ? astroPanel() : textPanel()}
    </div>
  );
};

export default CollectionContent;
