import React from 'react';
import CollectionContent from './CollectionContent';

const Collection = () => (
  <div className='container'>
    <CollectionContent />
  </div>
);

export default Collection;
