import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import Web3Context from '../../context/Web3Context';
import { shortenAddress } from '../../utils/web3Utils';
import styles from './Header.module.scss';

interface AccountInfoProps {
  active?: string;
}

const Header: React.FC<AccountInfoProps> = ({ active }) => {
  const [isModal, setisModal] = useState(false);
  const web3Controller = useContext(Web3Context);
  const { connected, address, initConnect, resetApp } = web3Controller;

  const toggleModal = () => {
    setisModal(!isModal);
  };

  const pinkBtnCss = [
    'font-black',
    'text-center',
    'text-white',
    'bg-purple',
    'rounded-lg',
    'hover:',
    'duration-150',
    styles.pinkBtn,
  ];

  return (
    <div className='bg-brand h-20 flex items-center sticky top-0 z-50'>
      <Link to='/'>
        <h1 className='ml-3.5'>
          <img src='images/logo_astrogator.png' alt='AstroGator' />
        </h1>
      </Link>
      <div className='ml-auto mr-7 hidden sm:block space-x-8'>
        <a
          href='https://astrogator.io/'
          className='py-4 font-black hover:text-purple duration-150'
          rel='noreferrer'
          target='_blank'
        >
          About
        </a>
        {connected ? (
          <>
            <Link
              to='/collection'
              className={`py-4 font-black hover:text-purple duration-150 ${active === 'Collection' && 'text-purple'}`}
              onClick={toggleModal}
            >
              My AstroGators
            </Link>
            <button className={pinkBtnCss.join(' ')} onClick={resetApp}>
              {address && shortenAddress(address)}
            </button>
          </>
        ) : (
          <button className={pinkBtnCss.join(' ')} onClick={initConnect}>
            Connect Wallet
          </button>
        )}
      </div>
      <button className={`ml-auto mr-6 sm:hidden ${styles.ham} ${isModal && styles.hamCross}`} onClick={toggleModal}>
        <span className='block w-6 bg-black' style={{ height: 2 }}></span>
        <span className='block w-6 bg-black' style={{ height: 2, margin: '2px 0' }}></span>
        <span className='block w-6 bg-black' style={{ height: 2 }}></span>
      </button>
      <div
        className={`fixed top-20 flex-col bg-brand flex left-0 right-0 pl-2 sm:hidden ${
          isModal ? (connected ? styles.show3 : styles.show) : styles.modalHidden
        } ${styles.modal}`}
      >
        <a
          href='https://astrogator.io/'
          className='p-4 font-black hover:text-purple duration-150'
          rel='noreferrer'
          target='_blank'
          onClick={toggleModal}
        >
          About
        </a>
        {connected ? (
          <>
            <Link
              to='/collection'
              className={`p-4 font-black hover:text-purple duration-150 ${active === 'Collection' && 'text-purple'}`}
              onClick={toggleModal}
            >
              My AstroGators
            </Link>
            <button
              className='p-4 font-black text-left hover:text-purple duration-150'
              onClick={() => {
                resetApp();
                toggleModal();
              }}
            >
              {address && shortenAddress(address)}
            </button>
          </>
        ) : (
          <button
            className='p-4 font-black text-left hover:text-purple duration-150'
            onClick={() => {
              initConnect();
              toggleModal();
            }}
          >
            Connect Wallet
          </button>
        )}
      </div>
    </div>
  );
};

export default Header;
