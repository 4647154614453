import React from 'react';
import SVG from 'react-inlinesvg';

const Footer = () => (
  <div className='bg-brand pt-12 pb-9'>
    <div className='container mx-auto flex flex-col sm:flex-row'>
      <div className='text-xs space-y-2 text-center sm:text-left'>
        <div>Copyright © 2021 AstroGator</div>
        <div className='flex items-center justify-center sm:justify-start'>
          Made with
          <div className='font-sans	'>&nbsp;❤️&nbsp;</div>
          by
          <a className='inline-block' href='https://lootex.io/' target='_blank' rel='noreferrer'>
            <SVG src={'images/logo_lootex.svg'} width={75} height={23} />
          </a>
        </div>
        <div className='flex flex-col sm:flex-row justify-center sm:justify-start py-2 font-black'>
          <a
            className='inline-block py-1'
            href='https://etherscan.io/address/0x2af743179e9d464c9bd687e90bc18f5ea146950f'
            target='_blank'
            rel='noreferrer'
          >
            View Contract
          </a>
        </div>
      </div>
      <div className='order-first mb-8 sm:order-last mx-auto sm:mx-0 sm:ml-auto space-x-6'>
        <a className='inline-block' href='https://twitter.com/astrogator_fam' target='_blank' rel='noreferrer'>
          <SVG src={'images/icons/twitter.svg'} width={32} height={32} />
        </a>
        <a className='inline-block' href='https://discord.gg/EhzTvTkXkP' target='_blank' rel='noreferrer'>
          <SVG src={'images/icons/discord.svg'} width={32} height={32} />
        </a>
      </div>
    </div>
  </div>
);

export default Footer;
