import React from 'react';
import { Hero, SalesPlan, Carousel, Roadmap, Founder } from './components';

const Home = () => (
  <>
    <Hero />
    <SalesPlan />
    <Carousel />
    <Roadmap />
    <Founder />
  </>
);

export default Home;
